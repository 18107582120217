import React from "react"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Header from "../components/header"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled, { ThemeProvider } from "styled-components"
import { mediaQueries } from "../style/mediaQueries"
import { theme } from "../style/theme"
import GlobalStyle from "../style/globalStyle"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  justify-content: center;
  align-items: center;

  @media only screen and (${mediaQueries.tabLand}) {
    height: 65vh;
  }
`

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <ThemeProvider theme={theme}>
        <SEO title="404: Not found" />
        <GlobalStyle/>
        <Header page={"404"} />
        <Container>
          <h1>{t("404")}</h1>
          <h2>{t("404.message")}</h2>
        </Container>
        <Footer footerStyle="footer-fixed" />
      </ThemeProvider>
    </>
  )
}

export default NotFoundPage
